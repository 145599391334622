<template>
  <v-container fluid class="d-flex flex-fill fill-height bg-login">
    <SnackBar />
    <v-row>
      <v-col cols="12" sm="6" offset-sm="3" md="4" offset-md="4">
        <v-card elevation="5">
          <div class="grey darken-4 pa-4">
            <v-img aspect-ratio="3.26" src="../assets/logo.png"></v-img>
          </div>
          <v-card-text class="px-4 pt-5">
            <v-text-field
              name="email"
              v-model="email"
              label="E-mail"
              required
            ></v-text-field>
            <v-text-field
              name="password"
              v-model="password"
              label="Senha"
              type="password"
              required
            ></v-text-field>
          </v-card-text>
          <v-card-actions class="d-flex justify-space-between flex-row-reverse">
            <v-btn raised color="primary" @click="login()">Logar</v-btn>
            <v-btn raised to="/app">Dashboard</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import jwt_decode from "jwt-decode";
import mixinSnackbar from "./../_mixins/snackbar";
import SnackBar from "../components/SnackBar";
export default {
  name: "Login",
  components: { SnackBar },
  mixins: [mixinSnackbar],
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    login() {
      let { email, password } = this;
      this.$axios
        .post("/login", { email, password })
        .then((res) => {
          const { data } = res;
          if (data != null) {
            localStorage.setItem("token", data.token);
            localStorage.setItem("username", jwt_decode(data.token).user.name);
            this.$router.push({ name: "Home" });
          }
        })
        .catch(({ response }) => {
          const { error } = response.data;
          if (error) {
            this.snackError(error);
          }
        });
    },
  },
};
</script>

<style scoped>
.bg-login {
  background-size: cover;
  background-position: center center;
  background-image: url("./../assets/bg-login.jpg");
}
</style>
